<template>
  <v-container
    fluid
    v-if="isItemProductList"
    class="in-category-item-container"
  >
    <v-row>
      <v-col>
        <div class="category-item-title-container">
          <h2>{{ isItemProductList ? isItemProductList.name : "" }}</h2>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        class="li-list"
        v-for="item in isItemProductList.productItem"
        :key="item.id"
        cols="12"
        lg="2"
        sm="3"
      >
        <router-link :to="`/provider-store/${providerId}/product/${item.id}`">
          <div class="category-item-wrap">
            <v-img
              v-if="item.photos[0]"
              width="100%"
              aspect-ratio="1"
              cover
              :src="item.photos[0].size_list.origin.url"
              class="category-item-wrap-img"
            >
            </v-img>
            <v-img
              v-else
              src="~@/assets/product-dafault.jpeg"
              width="100%"
              aspect-ratio="1"
              cover
              class="category-item-wrap-img"
            ></v-img>
            <h4>{{ item.name }}</h4>
          </div>
        </router-link>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "ProductItem",
  data() {
    return {
      categoryId: this.$route.params.categoryId,
    };
  },
  props: {
    productListKey: {
      type: Object,
      default: () => {},
    },
    providerId: {
      type: String,
    },
  },
  created() {
    // this.getProductApi();
    // console.log("is", this.isItemProductList);
  },
  computed: {
    // selectedProductList() {
    //   return this.productList.filter((i) => i.menus[0] === this.publicMenuId);
    // },
    isItemProductList() {
      return this.productListKey[this.$route.params.categoryId];
    },
  },
  methods: {},
};
</script>

<style>
.in-category-item-container {
  background-color: rgba(199, 199, 199, 0.2);
  padding: 30px;
  border-radius: 10px;
  overflow: hidden;
}
.in-category-item-container .category-item-title-container h2 {
  font-size: 20px;
  color: black;
  font-weight: 600;
}
.category-item-wrap {
  background-color: white;
  padding: 13px;
}
.category-item-wrap h4 {
  color: black;
  font-weight: 500;
  text-align: center;
}
.category-item-wrap-img {
  margin-bottom: 10px;
}
.li-list {
  transition: transform 0.3s ease-in-out;
}

.li-list:hover {
  transform: scale(1.1);
}
@media (max-width: 768px) {
  .in-category-item-container {
    margin: 40px 0;
  }
}
</style>
